
import { defineComponent, reactive } from "vue";

import style from "./style.module.less";
import { Service } from "@/api";

export default defineComponent({
  name: "Contribute",
  setup() {
    const state = reactive({ content: "", title: "" });

    Service.CommonController.getAnnouncement(1000).then(({ data }) => {
      state.content = data.content;
      state.title = data.title;
    });

    return () => (
      <div class={style.container}>
        <div class={style.main}>
          <div class={style.title}>{state.title}</div>
          <div innerHTML={state.content}></div>
        </div>
      </div>
    );
  },
});
